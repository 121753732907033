<template>
    <div>
        <mf-modal v-if="currentStep === 0 && isOpen" small :scrollable="false" @close="close">
            <div slot="header">
                Create Portcall
            </div>
            <div>
                <div class="modal__item">
                    <div class="modal__label">Choose a vessel for the new portcall:</div>
                    <mf-input placeholder="Vessel IMO" v-model="selectedVesselImo"></mf-input>
                </div>
                <div class="modal__item">
                    <div class="modal__label">Select a UNLOCODE for the new portcall:</div>
                    <mf-select placeholder="Search UNLOCODE" :options="displayedUnlocodes" v-model="selectedUnlocode">
                        <div slot="caret">
                            <mf-icon class="multiselect__select" icon-name="icon-down" />
                        </div>
                    </mf-select>
                </div>
                <div class="modal__item">
                    <div class="modal__label">Select the state you would like to update:</div>
                    <mf-select
                        placeholder="Search state to update"
                        :options="displayedStatesDefinitions"
                        label="name"
                        v-model="selectedState"
                    >
                        <div slot="caret">
                            <mf-icon class="multiselect__select" icon-name="icon-down" />
                        </div>
                    </mf-select>
                </div>
            </div>
            <div slot="footer">
                <div class="modal__buttons">
                    <mf-button class="modal__button" @click="close">Cancel</mf-button>
                    <mf-button
                        class="modal__button"
                        color="blue"
                        :disabled="!selectedVesselImo || !selectedUnlocode || !selectedState"
                        @click="createPortcall"
                    >
                        Next
                    </mf-button>
                </div>
            </div>
        </mf-modal>
        <update-state-modal
            v-if="currentStep === 1 && isOpen"
            :is-open="true"
            :state="selectedState"
            :port-call-id="newPortCall.portcallId"
            :vessel-imo="selectedVesselImo"
            is-first-state
            @portcallCreatedOrCanceled="handlePortcallCreatedOrCanceledEvent"
            @close="close"
        >
            <div slot="header">
                Create State
            </div>
        </update-state-modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import portCallPCR from '@/services/port-call-registry/portCallPCR';
    import UpdateStateModal from '@/modals/UpdateStateModal';
    import vesselVR from '@/services/vessel-registry/vesselVR';
    import { getQuayUnlocode } from '@/helpers/getQuayUnlocode.helper';
    import { badStatesIds } from '@/config/badStatesForNewPortcall';
    import { v4 as uuid } from 'uuid';

    export default {
        name: 'CreatePortcallModal',
        components: { UpdateStateModal },
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                statesDefinitions: 'statesDefinitions',
                quays: 'quays',
            }),
            newPortCall() {
                return {
                    vesselIMO: 'urn:mrn:stm:vessel:imo:' + this.selectedVesselImo,
                    portcallId: 'urn:mrn:stm:portcdm:port_call:' + this.selectedUnlocode.toLowerCase() + ':' + uuid(),
                };
            },
            displayedStatesDefinitions() {
                return this.statesDefinitions.filter(state => {
                    return !badStatesIds.includes(state.stateId) && state.createdBy === 'Carmenta';
                });
            },
            displayedUnlocodes() {
                return Array.from(new Set(this.quays.map(getQuayUnlocode)));
            },
        },
        mounted() {
            this.$store.dispatch('getStatesDefinitions');
            this.$store.dispatch('getQuays');
        },
        data() {
            return {
                selectedVesselImo: null,
                selectedUnlocode: null,
                selectedState: null,
                possibleVessels: [],
                currentStep: 0,
            };
        },
        methods: {
            handlePortcallCreatedOrCanceledEvent() {
                this.selectedVesselImo = this.selectedState = null;
                this.currentStep = 0;
            },
            close() {
                this.$emit('close');
            },
            async createPortcall() {
                try {
                    await portCallPCR.create({
                        portcallId: this.newPortCall.portcallId,
                        vesselId: this.newPortCall.vesselIMO,
                    });
                    await vesselVR.show(this.newPortCall.vesselIMO);
                    this.currentStep++;
                } catch {
                    this.$toasted.error('Most likely there is no such vessel');
                    this.close();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal__item:not(:first-child) {
        margin-top: $space-32;
    }

    .modal__label {
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
        margin-bottom: $space-16;
    }

    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .modal__button:not(:first-child) {
        margin-left: $space-8;
    }

    .vessel {
        display: flex;
        align-items: center;
    }

    .vessel__image {
        width: 50px;
        height: 50px;
        border-radius: 8px;
    }

    .vessel__info {
        margin-left: $space-8;
        display: flex;
        flex-direction: column;

        font-size: $font-14;
        line-height: $font-24;
        color: $white;
    }

    .vessel__value {
        font-weight: 700;
    }
</style>
