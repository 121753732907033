<template>
    <div>
        <mf-dropdown>
            <template slot="link">
                <div class="link__icon">
                    <img src="@/assets/icons/menu/user.svg" alt="user" />
                </div>
                <div class="link__text">
                    {{ userInfo.fullName }}
                </div>
            </template>
            <div class="menu__dropdown">
                <router-link :to="{ name: 'my-lists' }">
                    <div class="dropdown__item">
                        My Lists
                    </div>
                </router-link>
                <mf-divider />
                <router-link :to="{ name: 'about' }">
                    <div class="dropdown__item">
                        About PERSEUS port
                    </div>
                </router-link>
                <mf-divider />
                <a href="mailto:support@marine-fields.com">
                    <div class="dropdown__item">
                        Support
                    </div>
                </a>
                <mf-divider />
                <a href="javascript://" @click="showLogOutModal = true">
                    <div class="dropdown__item">
                        Log out
                    </div>
                </a>
            </div>
        </mf-dropdown>
        <log-out-modal v-if="showLogOutModal" @close="showLogOutModal = false" />
    </div>
</template>

<script>
    import LogOutModal from '@/modals/LogOutModal';
    import { mapGetters } from 'vuex';
    import MfDivider from '@/components/default/MfDivider.vue';

    export default {
        components: { MfDivider, LogOutModal },
        data() {
            return {
                showLogOutModal: false,
            };
        },
        computed: {
            ...mapGetters(['userInfo']),
        },
        methods: {
            close() {
                this.showLogOutModal = !this.showLogOutModal;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .link__icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $marine-600;
        border-radius: $border-radius-8;
        padding: $space-8;
        width: 32px;
        height: 32px;
        @include media($md) {
            width: 40px;
            height: 40px;
        }
    }

    .link__text {
        padding-left: $space-8;
        font-size: $font-14;
        line-height: $font-24;
        @include media($lg) {
            font-size: $font-18;
            padding-left: $space-12;
        }
    }

    .dropdown__item {
        padding: $space-16;
        font-weight: normal;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .dropdown__divider {
        height: 1px;
        width: 100%;
        background-color: $marine-600;
    }
</style>
