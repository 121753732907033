<template>
    <div class="container">
        <div class="container__header">
            About PERSEUS port
        </div>
        <div class="card">
            <img class="card__image" src="@/assets/images/about-background.png" alt="port" />
            <div class="card__text">
                <p class="card__paragraph">
                    <b>Perseus Situational Awareness Tool</b> (PERSEUS port) provides the user with situational awareness
                    of port calls being planned for, being in realization, or having been concluded. This is done
                    through combining multiple data sources of time stamps provided from different sources and the
                    different stakeholders involved in a port call. Data that is being used is enabled through intra-
                    and inter-port interactions as well as from the interaction between ships and ports, and hinterland
                    operators and ports. This means time stamps covering both intentions of movements and services
                    provided by different stakeholders engaged in / related to port call operations.
                </p>
                <p class="card__paragraph">
                    The data sharing principles and the principles of collaboration empowered by PERSEUS port is
                    compliant with guidelines issued and maintained by the International PortCDM council and the port
                    call message format as proposed to become an IALA S-211 standard.
                </p>
                <p class="card__paragraph">
                    PERSEUS port consumes timestamps provided by the neutral platform Platform, Perseus 2.0, allowing
                    everyone to connect and share time stamps in real-time.
                </p>
            </div>
            <div class="card__logo-container">
                <img class="card__logo" src="@/assets/images/logo.png" alt="logo" />
            </div>
            <div class="card__footer">
                <p>© 2025 Powered by: MarineFields Holding Ltd</p>
                <p>Version 1.0</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
    };
</script>

<style lang="scss" scoped>
    .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: $space-32 $space-8 $space-50;
        @include media($sm) {
            padding: $space-32 $space-16 $space-50;
        }
    }

    .container__header {
        font-weight: bold;
        font-size: $font-30;
        line-height: $font-36;
        color: $white;
        margin-bottom: $space-32;
    }

    .card {
        background: $marine-800;
        border-radius: 6px;
    }

    .card__image {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .card__text {
        font-size: $font-18;
        line-height: $font-36;
        color: $white;
        padding: $space-32 $space-32 $space-64;
    }

    .card__paragraph {
        margin: 1em 0;
    }

    .card__logo-container {
        display: flex;
        justify-content: center;
    }

    .card__logo {
        width: 412px;
    }

    .card__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        font-size: $font-14;
        line-height: $font-18;
        color: $white;

        padding: $space-32 $space-32 $space-64;
    }
</style>
