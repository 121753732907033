import vesselVR from '@/services/vessel-registry/vesselVR';
import formatVessel from '@/helpers/formatVessel.helper';
import { memoize } from 'lodash';

const getVessel = memoize(async vesselId => {
    return await vesselVR.show(vesselId);
});

export default async function addVesselToPortcall(portcall) {
    try {
        const vesselIMO = portcall.vesselId.replace(/\s/g, '');
        const vesselResponse = await getVessel(vesselIMO);
        portcall.vessel = await formatVessel(vesselResponse.data);
    } catch (error) {
        console.log(error);
    }
    return portcall;
}
